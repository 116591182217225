import React, { useEffect, useState } from "react";
import { baseURL, token } from "../utils/constant";
import axios from "axios";
import { Button, Form, Modal, Row } from "react-bootstrap";
import DiscountCouponTable from "./Tables/DiscountCouponTable";

export default function DiscountCoupon() {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [validated, setValidated] = useState(false);

  const [data, setData] = useState([]);

  const [isEditEvent, setIsEditEvent] = useState(false);

  const [title, setTitle] = useState("");
  const [discount, setDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [minimumAmount, setMiniMumAmmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState(0);
  

  const [selectedId, setSelectedId] = useState();

  async function getAllData() {
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoader(true);

    await fetch(`${baseURL}admin/getAllDiscountCoupon`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.Data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }

  function profileImagetoBase64(e) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() !== false) {
      setIsAdding(true);

      if (isEditEvent) {
        // update api
        const myHeaders = new Headers();
        myHeaders.append("authorization", token);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          discountCouponId: selectedId,
          title: title,
          discount: discount,
          description: description,
          minimumAmount: minimumAmount,
          startDate: startDate,
          endDate: endDate,
          image: image,
          chackMemberShip: 1,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        setValidated(true);
        fetch(`${baseURL}admin/updateDiscountCoupon`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            setIsAdding(false);
            getAllData();
          })
          .catch((error) => {
            alert(error.message);
            setIsAdding(false);
            console.log("error", error);
          });

        setTitle("");
        setDiscount("");
        setDescription("");
        setMiniMumAmmount("");
        setStartDate("");
        setEndDate("");
        setImage("");
        setStatus("");
      }

      // add api
      if (!isEditEvent) {
        await axios({
          url: `${baseURL}admin/addNewDiscountCoupon`,
          method: "POST",
          headers: {
            authorization: token,
          },
          data: {
            title: title,
            discount: discount,
            description: description,
            minimumAmount: minimumAmount,
            startDate: startDate,
            endDate: endDate,
            image: image,
            chackMemberShip: status,
          },
        })
          .then((result) => {
            setValidated(false);
            setShow(false);
            setIsAdding(false);
            getAllData();
          })
          .catch((error) => {
            alert(error.message);
            setIsAdding(false);
            console.log("error", error);
          });
      }
    } else {
      event.stopPropagation();
    }
  };

  // edit discount coupon
  function editDiscount(id) {
    handleShow();
    const selectDiscount = data?.filter((discount) => discount?._id === id);
    const title = selectDiscount[0]?.title;
    const discount = selectDiscount[0]?.discount;
    const description = selectDiscount[0]?.description;
    const minimumAmount = selectDiscount[0]?.minimumAmount;
    const startDate = selectDiscount[0]?.startDate;
    const endDate = selectDiscount[0]?.endDate;
    const status = selectDiscount[0]?.status;
    const image = baseURL + selectDiscount[0].image;

    setSelectedId(selectDiscount[0]?._id);
    setIsEditEvent(true);
    setTitle(title);
    setDiscount(discount);
    setDescription(description);
    setMiniMumAmmount(minimumAmount);
    setStartDate(startDate);
    setEndDate(endDate);
    setImage(status);
    setStatus(image);
  }

  function deleteDescount(id) {
    const selected = data?.filter((discount) => discount?._id === id);

    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      discountCouponId: selected[0]._id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseURL}admin/deleteDiscountCoupon`, requestOptions)
      .then((response) => response.json())
      .then((result) => getAllData(), setIsAdding(false))
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Discount Coupon</h3>
              <b> {data ? `${data?.length}  Data` : ``} </b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              Discount Coupon
            </button>
          </div>
        </div>
      </div>

      <DiscountCouponTable
        loader={loader}
        data={data}
        editDiscount={editDiscount}
        deleteDescount={deleteDescount}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditEvent ? `Edit Discount` : `Discount Coupon`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom05"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Title.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Discount</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Discount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Discount.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-12 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="textarea"
                  value={description}
                  placeholder="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Description.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="image"
                  onChange={profileImagetoBase64}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Iamge.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Minimum Amount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Min Amount"
                  value={minimumAmount}
                  onChange={(e) => setMiniMumAmmount(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Ammount.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Date.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Date.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-12 border"
                md="3"
                controlId="validationCustom04"
              >
                <div>
                  <Form.Check
                    className="m-1"
                    type="radio"
                    label={`Active`}
                    id={`Active`}
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(1)}
                  />
                  <Form.Check
                    className="m-1"
                    type="radio"
                    name="status"
                    label={`InActive`}
                    checked
                    id={`inActive`}
                    onChange={(e) => setStatus(0)}
                  />
                </div>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="success" disabled={isAdding && true}>
              {isAdding ? "Submiting.." : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
