import React, { useEffect, useState } from "react";
import { baseURL, token } from "../../../utils/constant";
import { Button, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import FueltypeTable from "../../Tables/FueltypeTable";

export default function Fueltype() {
  const [loader, setLoader] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isEditEvent, setIsEditEvent] = useState(false);
  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const [selectedId, setSelectedId] = useState();
  const [fuelType, setFuelType] = useState();
  const [allFuelType, setAllFuelType] = useState([]);

  const [fuelImage, setFuelImage] = useState();

 

  const handleClose = () => {
    setShow(false);
    setIsEditEvent(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  

  async function getAllFuel() {
    setLoader(true);
    await axios({
      url: `${baseURL}admin/getAllFuelType`,
      method: "POST",
      headers: {
        authorization: token,
      },
    })
      .then((res) => {
        setAllFuelType(res.data.Data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err===>", err);
      });
  }

  function profileImagetoBase64(e) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setFuelImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }
  

  // edit brand
  function editFuelType(id) {
    handleShow();
    const selectedFuelType = allFuelType?.filter(
      (fuelType) => fuelType?._id === id
    );
    const selectFuelTypeName = selectedFuelType[0]?.fuelType;
    const selectFuelTypeImage = selectedFuelType[0]?.image;
    setIsEditEvent(true);

    setFuelType(selectFuelTypeName);
    setFuelImage(selectFuelTypeImage);
    setSelectedId(selectedFuelType[0]?._id);
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (isEditEvent) {
      await axios({
        url: `${baseURL}admin/updateFuelType`,
        method: "POST",
        headers: {
          authorization: token,
        },
        data: {
          fuelTypeId: selectedId,
          fuelType: fuelType,
          image: isImageUpdate ? fuelImage : undefined,
        },
      })
        .then((result) => {
          setValidated(false);
          setShow(false);
          getAllFuel();

        })
        .catch((error) => console.log("error", error));
      setIsImageUpdate(false);
    }
    if (!isEditEvent) {
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        setIsAdding(true);
        //ADDING THE VALIDITY
        await axios({
          url: `${baseURL}admin/addNewFuelType`,
          method: "POST",
          headers: {
            authorization: token,
          },
          data: {
            fuelType: fuelType,
            image: fuelImage,
          },
        })
          .then((result) => {
            setValidated(false);
            setShow(false);
            setIsAdding(false);
            getAllFuel();
          })
          .catch((error) => console.log("error", error));
      }
    }
    setValidated(true);
  };
  
  
const deleteFuelType = async (id) => {
  
 const selectedFuelType = allFuelType?.find((fuelType) => fuelType?._id === id);
  
  if (!selectedFuelType) {
    console.error(`Fuel type not found for ID: ${id}`);
    console.log("Current allFuelType array:", allFuelType);
    return;
  }

  if (window.confirm("Are you sure you want to delete this Fuel Type?")) {
    try {
      const response = await axios.post(
        `${baseURL}admin/deleteFuelType`,
        { fuelTypeId: id }, 
        {
          headers: {
            authorization: token, 
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API response:", response.data);

      if (response.data.IsSuccess) {
        alert("Fuel type deleted successfully!");
        
        getAllFuel(); 
      } else {
        console.error("Failed to delete fuel type:", response.data.Message);
        alert(response.data.Message || "An error occurred while deleting.");
      }
    } catch (error) {
      console.error("Error while deleting fuel type:", error.message);
      alert("An error occurred. Please try again later.");
    }
  }
};


     //     <Button onClick={() => deleteFuelType(selectedId)}>Delete</Button>
//  deleteFuelType={deleteFuelType} 


 useEffect(() => {
    getAllFuel();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Fuel Type</h3>
              <b> {allFuelType ? `${allFuelType?.length} Fuel Type` : ``} </b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              + New Fuel Type
            </button>
          </div>
        </div>
      </div>
      

      <FueltypeTable
        loader={loader}
        allFuelType={allFuelType}
        editFuelType={editFuelType}  
        deleteFuelType={deleteFuelType}
        
      />



      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditEvent ? `Edit Fuel Type` : `Fuel Type`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group
                md="3"
                className="col-lg-12"
                controlId="validationCustom05"
              >
                <Form.Label>Fuel Type</Form.Label>
                <Form.Control
                  value={fuelType}
                  type="text"
                  placeholder="Fuel Type"
                  onChange={(e) => setFuelType(e.target.value)}
                  required={isEditEvent && false}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group
                md="3"
                className="col-lg-12"
                controlId="validationCustom04"
              >
                <Form.Label>Image</Form.Label>

                <Form.Control
                  type="file"
                  placeholder="image"
                  onChange={(e) => {
                    profileImagetoBase64(e);
                    setIsImageUpdate(true);
                  }}
                  required={isEditEvent ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              
              Close
            </Button>

            
         

<Button type="submit" variant="success" disabled={isAdding && true}>
              {isAdding ? "Submiting.." : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}




