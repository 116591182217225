import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { baseURL, token } from "../../../utils/constant";
import CarBrandTable from "../../Tables/CarBrandTable";

export default function CarBrand() {
  const inputRef = useRef(null);

  const [allCarBrand, setAllCarBrand] = useState([]);

  const [isEditEvent, setIsEditEvent] = useState(false);

  const [selectedId, setSelectedId] = useState();

  const [loader, setLoader] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [brandName, setBrandName] = useState();
  const [brandImage, setBrandImage] = useState();

  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const handleClose = () => {
    setShow(false);
    setBrandName(""); // Clear the brand name
    setBrandImage(null); // Clear the brand image
    setValidated(false); // Reset the form validation state
    setIsEditEvent(false);
  };

  const handleShow = () => {
    setShow(true);
    setBrandName("");
    setBrandImage(null); // Clear the brand image
    setValidated(false); // Reset the form validation state
    setIsEditEvent(false); // Ensure it's not in edit mode
  };

 

  async function getAllBrands() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);

    const raw = "";

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setLoader(true);
    await fetch(`${baseURL}admin/getAllCarBrand`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCarBrand(result.Data);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  }

  const profileImagetoBase64 = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setBrandImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (isEditEvent) {
      await axios({
        url: `${baseURL}admin/UpdateCarBrand`,
        method: "POST",
        headers: {
          authorization: token,
        },
        data: {
          carBrandId: selectedId,
          brandName: brandName,
          image: isImageUpdate && brandImage,
        },
      })
        .then((result) => {
          setValidated(false);
          setShow(false);
          getAllBrands();
        })
        .catch((error) => console.log("error", error));
      setIsImageUpdate(false);
    }
    if (!isEditEvent) {
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        setIsAdding(true);
        await axios({
          url: `${baseURL}admin/addNewCarBrand`,
          method: "POST",
          headers: {
            authorization: token,
          },
          data: {
            brandName: brandName,
            image: brandImage,
          },
        })
          .then((result) => {
            setValidated(false);
            setShow(false);
            setIsAdding(false);
            getAllBrands();
          })
          .catch((error) => console.log("error", error));
      }
    }
    setValidated(true);
  };

  // edit brand
  function editBrand(id) {
    handleShow();
    const selectedBrand = allCarBrand?.filter((brand) => brand?._id === id);
    const seleBrandName = selectedBrand[0]?.brandName;
    const seleBrandImage = selectedBrand[0]?.image;

    setIsEditEvent(true);
    setBrandName(seleBrandName);
    setBrandImage(seleBrandImage);
    setSelectedId(selectedBrand[0]?._id);
  }

  // edit brand
  async function deleteBrand(id) {
    if (window.confirm("Delete?")) {
      await axios
        .post(`${baseURL}admin/deleteCarBrand_v1`, { carBrandId: id })
        .then((resp) => {
          getAllBrands();
        })
        .catch((error) => console.log("error", error));
    }
  }

  useEffect(() => {
    getAllBrands();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Add Car Brand</h3>
              <b> {allCarBrand ? `${allCarBrand?.length} Car Brand` : ``} </b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              + New Brand
            </button>
          </div>
        </div>
      </div>

      <CarBrandTable
        loader={loader}
        allCarBrand={allCarBrand}
        deleteBrand={deleteBrand}
        editBrand={editBrand}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditEvent ? `Edit Brand` : `New Brand`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  ref={inputRef}
                  placeholder="image"
                  onChange={(e) => {
                    profileImagetoBase64(e);
                    setIsImageUpdate(true);
                  }}
                  required={isEditEvent ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom05"
              >
                <Form.Label>Brand Name</Form.Label>
                <Form.Control
                  type="text"
                  value={brandName}
                  placeholder="brand name"
                  onChange={(e) => setBrandName(e.target.value)}
                  required={isEditEvent ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="success" disabled={isAdding && true}>
              {isAdding ? "Submiting.." : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
