import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Modal, Row, Form } from "react-bootstrap";
import { baseURL, token } from "../../../utils/constant";
import CartypeTable from "../../Tables/CartypeTable";

export default function Cartype() {
  const [loader, setLoader] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [carType, setCarType] = useState("");
  const [allCarType, setAllCarType] = useState([]);

  const [isEditEvent, setIsEditEvent] = useState(false);

  const [carTypeImage, setCarTypeImage] = useState();
  const [selectedId, setSelectedId] = useState();
  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const handleClose = () => {
    setShow(false);
    setIsEditEvent(false);
  };
  const handleShow = () => {
    setShow(true);
    setCarType("");
  };

  async function getAllCarType() {
    setLoader(true);
    await axios({
      url: `${baseURL}admin/getAllCarType`,
      method: "POST",
      headers: {
        authorization: token,
      },
    })
      .then((res) => {
        setAllCarType(res.data.Data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err===>", err);
      });
  }

  function profileImagetoBase64(e) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setCarTypeImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }

  // edit brand
  function editBrand(id) {
    handleShow();
    const selectedCarType = allCarType?.filter(
      (carType) => carType?._id === id
    );

    const selectCarTypeName = selectedCarType[0]?.carType;
    const selectCarTypeImage = selectedCarType[0]?.image;

    setIsEditEvent(true);

    setCarType(selectCarTypeName);
    setCarTypeImage(selectCarTypeImage);
    setSelectedId(selectedCarType[0]?._id);
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (isEditEvent) {
      await axios({
        url: `${baseURL}admin/updateCarType`,
        method: "POST",
        headers: {
          authorization: token,
        },
        data: {
          carTypeId: selectedId,
          carType: carType,
          image: isImageUpdate && carTypeImage,
        },
      })
        .then((result) => {
          setValidated(false);
          setShow(false);
          getAllCarType();
        })
        .catch((error) => console.log("error", error));
      setIsImageUpdate(false);
    }
    if (!isEditEvent) {
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        setIsAdding(true);
        await axios({
          url: `${baseURL}admin/addNewCarType`,
          method: "POST",
          headers: {
            authorization: token,
          },
          data: {
            carType: carType,
            image: carTypeImage,
          },
        })
          .then((result) => {
            setValidated(false);
            setShow(false);
            setIsAdding(false);
            getAllCarType();
          })
          .catch((error) => console.log("error", error));
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    getAllCarType();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Body Type</h3>
              <b> {allCarType ? `${allCarType?.length}  Body Type` : ``} </b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              + New Body Type
            </button>
          </div>
        </div>
      </div>

      <CartypeTable
        loader={loader}
        allCarType={allCarType}
        editBrand={editBrand}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditEvent ? `Edit Body Type` : `Body Type`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group
                className="col-lg-12"
                md="3"
                controlId="validationCustom05"
              >
                <Form.Label> Body Type</Form.Label>
                <Form.Control
                  type="text"
                  value={carType}
                  placeholder="Body Type"
                  onChange={(e) => setCarType(e.target.value)}
                  required={isEditEvent && false}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group
                className="col-lg-12"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="image"
                  onChange={(e) => {
                    profileImagetoBase64(e);
                    setIsImageUpdate(true);
                  }}
                  required={isEditEvent ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="success" disabled={isAdding && true}>
              {isAdding ? "Submiting.." : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
 
