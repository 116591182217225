import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { baseURL, token } from "../../../utils/constant";
import axios from "axios";
import ServiceTable from "../../Tables/ServiceTable";
export default function Service() {
  const inputRef = useRef(null);
  const [allService, setAllService] = useState([]);
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState();
  const [submiting, setSubmiting] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [isEditEvent, setIsEditEvent] = useState(false);
  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const [inputValues, setInputValues] = useState({
    categoryName: "",
    image: "",
  });

  const handleClose = () => {
    
    setInputValues({
      categoryName: "",
      image: "",
    });
    setImage(null);
    setValidated(false);
    setShow(false);
    setIsEditEvent(false);
    setIsImageUpdate(false);
  };

  const handleShow = () => {
    
    setInputValues({
      categoryName: "",
      image: "",
    });
    setImage(null);
    setValidated(false);
    setShow(true);
    setIsEditEvent(false);
  };


  function getAllServiceCategories() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);
    setLoader(true);
    const raw = "";
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseURL}admin/getServiceCategory`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllService(result.Data);
        setLoader(false);
      })
      .catch((error) => console.log("error", error));
  }

  // submit
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    setValidated(true);
    if (form.checkValidity() !== false) {
      if (!isEditEvent) {
        var data = JSON.stringify({
          categoryName: inputValues?.categoryName,
          image: image,
        });

        var config = {
          method: "post",
          url: `${baseURL}admin/addNewServiceCategory`,
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        setSubmiting(true);
        setLoader(true);

        await axios(config)
          .then(function (response) {
            setSubmiting(false);
            setLoader(false);
            setShow(false);
            getAllServiceCategories();
          })
          .catch(function (error) {
            setSubmiting(false);
            console.log(error);
          });
      }
      if (isEditEvent) {
        const data = {
          serviceCategoryId: selectedId,
          categoryName: inputValues?.categoryName,
          image: isImageUpdate && image,
        };

        setSubmiting(true);
        await fetch(`${baseURL}admin/updateServiceCategory`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            setIsEditEvent(false);
            setSubmiting(false);
            setInputValues({
              categoryName: "",
              image: "",
            });
            setValidated(false);
            setShow(false);
            getAllServiceCategories();
          })
          .catch((error) => {
            setSubmiting(false);
            console.error("Error:", error);
          });
        setIsImageUpdate(false);
      }
    } else {
      setShow(true);
      event.stopPropagation();
    }
  };

  // changeInputValues
  function changeInputValues(evt) {
    const value = evt.target.value;
    setInputValues({
      ...inputValues,
      [evt.target.name]: value,
    });
  }

  // profileImagetoBase64
  const profileImagetoBase64 = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  };

  // deleteRegulerService
  const deleteRegulerService = async (id) => {
    const selected = allService?.filter((brand) => brand?._id === id);

    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      serviceCategoryId: selected[0]?._id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    if (window.confirm("Delete?")) {
      await fetch(`${baseURL}admin/deleteServiceCategory`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          getAllServiceCategories();
        })
        .catch((error) => {
          alert(error?.message);
          console.log("error", error);
        });
    }
  };

  function editRegulerService(id) {
    setShow(true);
    const matchedData = allService?.filter((service) => service?._id === id);
    setInputValues({
      categoryName: matchedData[0]?.categoryName,
      image: matchedData[0]?.icon,
    });
    setIsEditEvent(true);
    setSelectedId(matchedData[0]?._id);
  }

  useEffect(() => {
    getAllServiceCategories();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Service</h3>
              <b> {allService ? `${allService?.length} Service` : ``} </b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              + Subcategory
            </button>
          </div>
        </div>
      </div>

      <ServiceTable
        loader={loader}
        allService={allService}
        deleteRegulerService={deleteRegulerService}
        editRegulerService={editRegulerService}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditEvent ? "Edit" : "Add"} Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group
                className="col-lg-12"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  name="categoryName"
                  value={inputValues?.categoryName}
                  onChange={changeInputValues}
                  placeholder="Category Name"
                  required={isEditEvent ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group
                className="col-lg-12"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Image</Form.Label>
                {isEditEvent !== true ? (
                  <>
                    <Form.Control
                      type="file"
                      ref={inputRef}
                      onChange={function (e) {
                        profileImagetoBase64(e);
                        setIsImageUpdate(true);
                      }}
                      placeholder="image"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a Field.
                    </Form.Control.Feedback>
                  </>
                ) : (
                  <Form.Control
                    type="file"
                    ref={inputRef}
                    onChange={function (e) {
                      profileImagetoBase64(e);
                      setIsImageUpdate(true);
                    }}
                    placeholder="image"
                  />
                )}
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" className="btn-success" varint="success">
              {submiting ? "Submitng" : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
